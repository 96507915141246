import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Img from '../images/scf-icon.png'

import Menu from './menu'

const Header = props => {
  const { siteLogo, logoText, mainMenu, mainMenuItems, menuMoreText } = props

  useEffect(() => {
    const header = document.getElementById('header')
    const sticky = header.offsetTop
    const scrollCallBack = window.addEventListener('scroll', () => {
      if (window.pageYOffset > sticky) {
        header.classList.add('sticky')
      } else {
        header.classList.remove('sticky')
      }
    })
    return () => {
      window.removeEventListener('scroll', scrollCallBack)
    }
  }, [])

  const [isMobileMenuVisible, toggleMobileMenu] = useState(false)
  const onToggleMobileMenu = () => toggleMobileMenu(!isMobileMenuVisible)

  return (
    <>
      <Helmet>
        <body className="light-theme" />
      </Helmet>
      <header className="header" id="header">
        <div className="inner">
          <Link to="/">
            <div className="logo">
              <>
                <img src={Img} alt={siteLogo.alt} />
                <span className="text">{logoText}</span>
              </>
            </div>
          </Link>
          <span className="right">
            <Menu
              mainMenu={mainMenu}
              mainMenuItems={mainMenuItems}
              isMobileMenuVisible={isMobileMenuVisible}
              menuMoreText={menuMoreText}
              onToggleMobileMenu={onToggleMobileMenu}
            />
          </span>
          <button
            className={`hamburger hamburger--spin ${
              isMobileMenuVisible ? 'is-active' : ''
            }`}
            type="button"
            onClick={onToggleMobileMenu}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  siteLogo: PropTypes.object,
  logoText: PropTypes.string,
  mainMenu: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  mainMenuItems: PropTypes.number,
  menuMoreText: PropTypes.string,
}

export default Header
