import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from "gatsby-image"
import Navigation from './navigation'
import PostListItem from './postListItem'
import { toKebabCase } from '../helpers'

const Post = ({
  title,
  date,
  path,
  coverImage,
  author,
  excerpt,
  tags,
  html,
  previousPost,
  nextPost,
}) => {
  const previousPath = previousPost && previousPost.frontmatter.path
  const previousLabel = previousPost && previousPost.frontmatter.title
  const nextPath = nextPost && nextPost.frontmatter.path
  const nextLabel = nextPost && nextPost.frontmatter.title
  const isPage = path.includes('/about') || path.includes('/portfolio')
  if (excerpt) {
    return (
      <PostListItem
        title={title}
        date={date}
        path={path}
        author={author}
        coverImage={coverImage}
        tags={tags}
        html={html}
        excerpt={excerpt}
      />
    )
  }
  return (
    <div className="post">
      <div className="postContent">
        <h1 className="title">
          {excerpt ? <Link to={path}>{title}</Link> : title}
        </h1>
        {!isPage &&
          (
            <div className="meta">
              <p>
                {date} {author && <>— Written by {author}</>}
              </p>
              {tags ? (
                <div className="tags">
                  {tags.map(tag => (
                    <Link to={`/tag/${toKebabCase(tag)}/`} key={toKebabCase(tag)}>
                      <span className="tag">#{tag}</span>
                    </Link>
                  ))}
                </div>
              ) : null}
            </div>
          )
        }
        {coverImage && (
          <Img
            fluid={coverImage.childImageSharp.fluid}
            className="coverImage"
          />
        )}

        {excerpt ? (
          <>
            <p>{excerpt}</p>
            <Link to={path} className="readMore">
              Read more →
            </Link>
          </>
        ) : (
            <>
              <div dangerouslySetInnerHTML={{ __html: html }} />
              <Navigation
                previousPath={previousPath}
                previousLabel={previousLabel}
                nextPath={nextPath}
                nextLabel={nextLabel}
              />
            </>
          )}
      </div>
    </div>
  )
}

Post.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  path: PropTypes.string,
  coverImage: PropTypes.object,
  author: PropTypes.string,
  excerpt: PropTypes.string,
  html: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  previousPost: PropTypes.object,
  nextPost: PropTypes.object,
}

export default Post
