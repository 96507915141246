
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from "gatsby-image"

const PostListItem = ({ title, date, path, coverImage, author, excerpt, html, tags }) => {
  console.log('img', excerpt)
  return (
    <Link className="blogpost-link" to={path}>
      <div className="post-list-item">
        <div className="img">
          <Img fluid={coverImage.childImageSharp.fluid} alt={title} />
        </div>
        <div>
          <h2>{title}</h2>
          <p>{excerpt}</p>
        </div>
      </div>
    </Link>
  )
}

PostListItem.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  path: PropTypes.string,
  coverImage: PropTypes.object,
  author: PropTypes.string,
  excerpt: PropTypes.string.isRequired,
  html: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
}
export default PostListItem;