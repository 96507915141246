import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const MainMenu = ({ mainMenu }) => {
  const menu = mainMenu.slice(0)
  return menu.map((menuItem, index) => (
    <li key={index}>
      <Link to={menuItem.path}>{menuItem.title}</Link>
    </li>
  ))
}

const Menu = ({ isMobileMenuVisible, mainMenu, mainMenuItems }) => {
  return (
    <div
      className={`menu-wrapper ${isMobileMenuVisible ? 'mobile-visible' : ''}`}
    >
      <ul className="menu">
        <MainMenu mainMenu={mainMenu} mainMenuItems={mainMenuItems} />
      </ul>
    </div>
  )
}

Menu.defaultProps = {
  isMobileMenuVisible: false,
}

Menu.propTypes = {
  mainMenu: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  mainMenuItems: PropTypes.number,
  isMobileMenuVisible: PropTypes.bool,
}

export default Menu
