import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'

import '../styles/index.scss'

const Layout = ({ children, homepage }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          logo {
            src
            alt
          }
          logoText
          defaultTheme
          copyrights
          mainMenu {
            title
            path
          }
          showMenuItems
          menuMoreText
        }
      }
    }
  `)
  const {
    title,
    logo,
    logoText,
    defaultTheme,
    mainMenu,
    showMenuItems,
    menuMoreText,
    copyrights,
  } = data.site.siteMetadata

  if (!homepage) {
    return (
      <div className='container'>
        <Header
          siteTitle={title}
          siteLogo={logo}
          logoText={logoText}
          defaultTheme={defaultTheme}
          mainMenu={mainMenu}
          mainMenuItems={showMenuItems}
          menuMoreText={menuMoreText}

        />
        <div className="content">{children}</div>
        <Footer copyrights={copyrights} />
      </div>
    )
  }
  return (
    <div className='homepage'>
      <Header
        siteTitle={title}
        siteLogo={logo}
        logoText={logoText}
        defaultTheme={defaultTheme}
        mainMenu={mainMenu}
        mainMenuItems={showMenuItems}
        menuMoreText={menuMoreText}

      />
      {children}
    </div>

  )
}

Layout.defaultProps = {
  homepage: false,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  homepage: PropTypes.bool,
}

export default Layout
