import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'

export default function home({ data }) {
  return (
    <Layout homepage>
      <div className="wrapper">
        <Img fluid={data.file.childImageSharp.fluid} />
        <div className="inner">
          <h1>Web Development</h1>
          <div className="split">
            <Link to="/portfolio">Portfolio</Link>
            <Link to="/blog">Blog</Link>
            <Link to="/about">About</Link>
          </div>
        </div>
      </div>
      {/* <div className="inner"></div> */}
    </Layout >
  )
}

export const query = graphql`
  query {
    file(relativePath: { glob: "iceberg*" }) {
      publicURL
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth:1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

home.propTypes = {
  data: PropTypes.object.isRequired,
}
